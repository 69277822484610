<template>
  <div :class="addClass">
    <div :class="formClass">
      <label v-if="formLabel" class="form__label form__label--block">{{ getTranslation(formLabel) }} <span v-if="required" class="required require">*</span></label>
      <span v-if="description" class="text text-muted">{{ getTranslation(description) }}</span>
      <v-select
          ref="countrySelect"
          v-model="country_id"
          :options="countries"
          :reduce="option=>option.id"
          @search:blur="!country_id ? customValidator.country_id = false : customValidator.country_id = true"
          :class="{ 'is-invalid': !customValidator.country_id && required}"
          :placeholder="getTranslation('selectFromList')"
          label="fullText"
      />
      <div v-if="required && !customValidator.country_id" class="invalid-feedback require">{{ getTranslation('requiredField') }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CountrySelect",
  props: {
    editCountryId: {
      type: [ Number, String ]
    },
    required: Boolean,
    description: String,
    addClass: String,
    formLabel: {
      type: String,
      default: 'country'
    },
    formClass: {
      type: String,
      default: 'form-group'
    },
  },
  data() {
    return {
      countries: [],
      country_id: '',
      timer: null,
      showSelect: false,
      customValidator: {
        country_id: true,
      }
    }
  },
  watch: {
    'country_id'(id) {

      this.$emit('input', id);

      if ( !id ) {
        this.fetchCountries();
      }
    }
  },
  created() {
    this.fetchCountries().then(() => {
      if ( this.editCountryId )
        this.country_id = this.editCountryId;
    })
  },
  mounted() {
    let searchInput = this.$refs.countrySelect.$el.getElementsByClassName('vs__search');
    let searchText = '';

    if ( searchInput.length > 0 ) searchInput[0].oninput = e => {
      if ( this.timer ) clearTimeout(this.timer);
      if ( searchText !== e.target.value ) {
        this.timer = setTimeout(() => {
          searchText = e.target.value;
          this.searchCountry(searchText);
          this.timer = null;
        }, 500);
      }
    };
  },
  methods: {

    fetchCountries() {
      return this.axios.get(this.$urls.portal.countries.url, { params: { count: 50 } }).then(response => {
        this.countries = response.data.data.items;
        if ( this.editCountryId )
          this.country_id = this.editCountryId;
      }).catch(()=>{});
    },
    searchCountry(query) {
      let params = {};
      if ( query ) params.query = query;

      return this.axios.get(this.$urls.portal.countries.url, { params: params })
          .then(response => {
            this.countries = response.data.data.items;
          }).catch(()=>{});
    },
    // We will call this method from parent component for check validity
    checkValidity() {
      if ( !this.country_id ) {
        this.customValidator.country_id = false;
        return false;
      }

      return true;
    }
  }
}
</script>

<style scoped>

</style>